.PureSeedDefinitionModal {
  padding: 2em;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  gap: 1em;

  .HeaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75em;
    font-weight: bold;

    .Header {
      font-size: 1.5em;
    }

    .SubHeader {
      font-size: 1.25em;
    }
  }

  .Definition {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: auto;

    p {
      padding-block: 0.25em;
    }
  }

  .BtnContainer {
    align-self: flex-start;
  }
}
