.ExceedsMaxWizard {
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;

  .Heading {
    font-size: 1.5em;
    text-align: center;
  }

  .Question {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .Buttons {
      display: flex;
      justify-content: center;
      margin: 0.5em;

      button {
        min-width: 8em;
      }
    }
  }
}

.CloseButton {
  margin: 1em;
  min-width: 8em;
  align-self: center;
}

.Refuse {
  font-size: 3em;
  font-weight: bolder;
  color: red;
}
