.Home {
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5em;

    span {
      font-size: 1.5em;
      font-weight: bolder;
      text-align: center;
    }

    img {
      max-height: 6em;
      object-fit: contain;
    }
  }

  .SpeciesList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-block: 0.25em;

    .Results {
      text-align: center;
    }
  }

  .SpeciesItem {
    margin: 0.15em 0;
    cursor: pointer;
    border: 2px solid grey;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;

    &:hover {
      background-color: #7ab254;
    }

    .Name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 1em;

      padding: 0.5em 1em;
      font-size: 1.25em;

      .Table {
        margin-inline-start: auto;
        font-size: 0.96em;
      }
    }

    .Info {
      display: flex;
      padding: 0.35em 1em;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.15);
      font-size: 1.2em;

      .Data {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .FilterInput {
    display: flex;
    margin: 0.5em 0;
    justify-content: center;

    label {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    input {
      font-size: 1.25em;
      box-sizing: border-box;
      width: 100%;
      margin-block-start: 0.25em;
    }
  }

  .Favourite {
    border-color: #fa7c18;
    border-width: 3px;
  }

  .Credits {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    span,
    a {
      text-align: center;
    }
  }

  .Help {
    flex-grow: 0;
  }
}
