html,
body {
  min-height: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 0.8em;
}

hr {
  width: 98%;
}

#root {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
  max-height: 100%;
  max-width: 480px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input,
button {
  font-size: 1em;
  margin: 0;
  padding: 0.5em;
}

input,
button,
.Box {
  border: 2px solid grey;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

input {
  min-width: 0;
}

button {
  min-width: 5em;
  cursor: pointer;
  padding-block: 0.5em;
  padding-inline: 1em;
}

.error {
  background-color: red;
}

.ValueContainer {
  display: flex;
  flex-direction: column;
}

.Value {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
}

.InputGroup {
  display: flex;
  flex-direction: column;
  padding: 0.25em 0.5em;

  .Label {
    display: flex;
    padding: 0.25em 0.5em;
  }
}

.active {
  background-color: #7ab254;
}

.start {
  border-start-end-radius: unset;
  border-end-end-radius: unset;
  border-inline-end-width: 1px;
}

.middle {
  border-radius: unset;
  border-inline-start-width: 1px;
  border-inline-end-width: 1px;
}

.end {
  border-start-start-radius: unset;
  border-end-start-radius: unset;
  border-inline-start-width: 1px;
}

.ValueGroups {
  display: grid;
  grid-gap: 0.5em 1em;
  grid-template-columns: auto 1fr auto;

  align-items: center;
  padding: 0.5em 1em;

  .Label {
    display: flex;
    justify-content: space-between;
  }

  .Content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    white-space: nowrap;
  }

  .Bold {
    font-weight: bold;
  }

  .Big {
    font-size: 1.1em;
  }
}

p {
  margin-block: 0;
}
