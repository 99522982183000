.SpeciesInfo {
  margin: 0 0.5em;

  button {
    margin: 0;
    width: 100%;
  }

  button.Show {
    border-start-start-radius: unset;
    border-start-end-radius: unset;
  }

  .BoarderBox {
    border: 2px solid grey;
    border-block-end: unset;
    border-start-start-radius: 5px;
    border-start-end-radius: 5px;
  }

  .Hide {
    display: none;
  }

  .Span3Columns {
    display: flex;
    justify-content: center;
    grid-column: 1 / 4;
  }

  .Disclaimer {
    display: flex;
    justify-content: center;
    grid-column: 1 / 4;
    margin-block-start: 0.5em;
  }

  .Genus {
    font-style: italic;
  }
}
