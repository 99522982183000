.HelpModalBackground {
  position: fixed;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  background-color: #222e;
  color: white;
  backdrop-filter: blur(15px) grayscale(100%);

  display: flex;
  justify-content: center;
  align-items: center;

  .HelpModal {
    .ModalContainer {
      max-height: 95vh;
      overflow: auto;

      .Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 480px;
        padding: 2em;
        font-size: 1.5em;

        p {
          display: flex;
          align-self: flex-start;
          text-justify: newspaper;
        }
      }
    }
  }
}
