.ExtraInfo {
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.15);
  gap: 0.5em;
  align-items: center;

  .SingleExtraInfoInfo {
    display: flex;
    flex-direction: column;
  }
}

.Header {
  display: flex;
  justify-content: center;

  font-size: 1.4em;
  font-weight: bold;
  text-align: center;
  padding: 0.5em 2.5em;
}

.Favourite {
  background-color: #fa7c18;
}

.HeaderButtons {
  display: flex;
  justify-content: space-around;
  margin: 0.5em 0.5em 0 0.5em;

  button {
    margin: 0.25em;
  }
}
