.Modal {
  position: fixed;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  background-color: #333e;
  backdrop-filter: blur(15px) grayscale(100%);

  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .Container {
    max-height: 95vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }

  @media (min-width: 576px) {
    .Container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .Container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .Container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .Container {
      max-width: 1140px;
    }
  }

  @media (min-width: 1400px) {
    .Container {
      max-width: 1320px;
    }
  }
}
