.DefaultValues {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;

    span {
        margin-inline-end: 0.5em;
    }
}
