.ScaledInput {
  display: flex;

  input {
    flex-grow: 1;
  }

  button {
    min-width: 4em;
    cursor: pointer;
  }

  .Start {
    border-start-end-radius: unset;
    border-end-end-radius: unset;
    border-inline-end-width: 1px;
  }

  .Middle {
    border-radius: unset;
    border-inline-start-width: 1px;
    border-inline-end-width: 1px;
  }

  .End {
    border-start-start-radius: unset;
    border-end-start-radius: unset;
    border-inline-start-width: 1px;
  }
}
