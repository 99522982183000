.LanguageButton {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  inset-inline-end: 0.25em;
  inset-block-start: 0.25em;
  padding: 0.25em;
  gap: 0.5em;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.8em;
  }
}

.LanguageSelect {
  display: flex;
  flex-direction: column;
  padding: 2em;
  gap: 0.5em;
  overflow-y: hidden;

  .Title {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .LangList {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    overflow-y: auto;

    .Lang {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .LocalName {
        font-size: larger;
        font-weight: bolder;
      }
      .EnglishName {
        font-size: smaller;
      }
    }
  }

  .Close {
    align-self: flex-start;
  }
}
