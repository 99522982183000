.RefuseSampling {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em;

  .Heading {
    font-weight: bold;
  }

  &.Error {
    background-color: #ffcccc;
    color: darken($color: #ffcccc, $amount: 55);
    font-size: 1.2rem;

    .Heading {
      font-size: 1.8em;
    }
  }

  &.Warning {
    background-color: #ffe6cc;
    color: darken($color: #ffe6cc, $amount: 55);
    font-size: 1.1em;

    .Heading {
      font-size: 1.5em;
    }
  }
}
