.Heterogeneity {
    display: flex;
    flex-direction: column;
    flex-Grow: 1;
    justify-content: space-between;

    .DisableButton {
        display: flex;
        justify-content: center;
        margin-block-end: 0.5em;
    }

    .Info {
        margin: 1em 0;
        padding: 1em 2em;
        background-color: #eee;
    }
}

.Header {
    display: flex;
    justify-content: center;
}
